import React, {Component} from "react"
import {connect} from "react-redux"
import L from "../../lang/L"
import {classNames} from "../../tools/helpers"
import {
	isItemSelected,
	openCommentPopup,
	setDailyStateComment,
	storeDailyState,
	toggleStateItem
} from "../../modules/DailyStateModule"
import {Textarea} from "@vkontakte/vkui"
import {FixedLayout, Button} from "@vkontakte/vkui"
import "./DayState.css"

class DayState extends Component {

	getCategories() {
		return this.props.stateSchema
	}

	isActiveItem(item, index, category) {
		return isItemSelected(this.props.dailyStateData, category.name, index)
	}

	toggleItem(item, index, category) {
		this.props.toggleStateItem(category.name, index)
	}

	renderItem(item, index, category) {
		const className = classNames({
			"DayState__item" : true,
			"DayState__item--active" : this.isActiveItem(item, index, category),
		})
		return <div onClick={() => this.toggleItem(item, index, category)}
					key={index}
					className={className}>{item}</div>
	} 

	renderCategory = (category, index) => {
		if (!this.props.isBadDay && category.name === "fill") return null
		return <div className="DayState__category" key={index}>
			<div className="DayState__category-name">{L.t("category_"+category.name)}</div>
			<div className="DayState__category-items">
				{category.items.map( (item, index) => this.renderItem(item, index, category))}
			</div>
		</div>
	}

	getShort() {
		let selected = this.getCategories().reduce( (buff,category) => {
			return buff.concat( category.items.filter( (item,index) => this.isActiveItem(item, index, category) ) )
		}, [] )

		if (selected.length === 0 && !this.props.comment) {
			return <div className="DayState__empty">{L.t('no_data_for_this_day')}</div>
		}

		return <div>
			{this.props.comment ? <div className="DayState__comment">{this.props.comment}</div> : null}
			{selected.length ? <div className="DayState__selected-items">{selected.join(", ")}</div> : null}
		</div>
	}

	render() {
		let classList = ["DayState"]
		if (!this.props.short && this.props.edit) {
			classList.push("DayState--confirmable")
		}
		if (!this.props.short && !this.props.edit) {
			classList.push("DayState--commentable")
		}
		return <div className={classList.join(' ')}>
			{ this.props.short ? this.getShort() : <div>
				{this.props.comment && this.props.comment.length && !this.props.edit ? <div>
					<div className="DayState__category-name">{L.t('comment')}</div>
					<div className="DayState__comment-static">
						{this.props.comment}
					</div>
				</div> : null}
				{this.props.edit ? <div className="DayState__comment-dynamic">
						<Textarea onChange={(e) => this.props.setDailyStateComment(e.target.value)}
								  defaultValue={this.props.comment || ""}
								  value={this.props.comment || ""}
								  placeholder={L.t('comment_placeholder')}/>
				</div> : null}
				<div>
					{this.getCategories().map( this.renderCategory )}
				</div>
				{!this.props.edit ? <FixedLayout className="DayState__comment-button" vertical="bottom">
					<Button size="xl" onClick={() => this.props.openCommentPopup()}>
					<span className="DayState__pen">
					</span>
						<span>
						{L.t('comment')}
					</span>
					</Button>
				</FixedLayout> : null}
			</div>}
		</div>
	}
}

function map(state) {
	return {
		stateSchema: state.BootstrapModule.stateSchema,
		dailyStateData: state.DailyStateModule.state,
		loading: state.DailyStateModule.loading,
		comment: state.DailyStateModule.comment,
	}
}

export default connect(map, {toggleStateItem, setDailyStateComment, storeDailyState, openCommentPopup})(DayState)
