import React, {Component} from "react"
import {connect} from "react-redux"
import Calendar from "../Calendar/Calendar"
import {openStateForSelectedDay} from "../../modules/DailyStateModule"
import {GetDayOfCycle} from "../../tools/helpers"
import moment from "moment"
import L from "../../lang/L"
import "./CalendarScreen.css"

class CalendarScreen extends Component {

	scrollNode = null
	firstMonthNode = null

	componentDidMount() {
		if (this.scrollNode && this.firstMonthNode) {
			this.scrollNode.scrollTop = this.firstMonthNode.offsetTop - this.firstMonthNode.parentNode.offsetTop
		}
	}

	render() {
		const {cycleLength, menstruatedAt, menstruationLength, selectedDate} = this.props

		const dayOfCycle = GetDayOfCycle(cycleLength, menstruatedAt.timestampMs(), selectedDate)
		const isBadDay = dayOfCycle <= menstruationLength

		let classList = ["CalendarScreen"]
		if (!isBadDay) {
			classList.push("CalendarScreen--green")
		}
		return <div style={ {maxHeight: this.props.height+'px'} } className={classList.join(' ')}>
			<div className="CalendarScreen__week-day-list">
				<div className="CalendarScreen__week-day">{L.t('week_day_short_1')}</div>
				<div className="CalendarScreen__week-day">{L.t('week_day_short_2')}</div>
				<div className="CalendarScreen__week-day">{L.t('week_day_short_3')}</div>
				<div className="CalendarScreen__week-day">{L.t('week_day_short_4')}</div>
				<div className="CalendarScreen__week-day">{L.t('week_day_short_5')}</div>
				<div className="CalendarScreen__week-day">{L.t('week_day_short_6')}</div>
				<div className="CalendarScreen__week-day">{L.t('week_day_short_0')}</div>
			</div>
			<div ref={node => this.scrollNode = node} className="CalendarScreen__scroll-wrapper">
				<Calendar onClick={this.props.openStateForSelectedDay} month={moment().subtract(2, 'month')}/>
				<Calendar onClick={this.props.openStateForSelectedDay} month={moment().subtract(1, 'month')}/>
				<Calendar onRef={node => this.firstMonthNode = node} onClick={this.props.openStateForSelectedDay} month={moment()}/>
				<Calendar onClick={this.props.openStateForSelectedDay} month={moment().add(1, 'month')}/>
				<Calendar onClick={this.props.openStateForSelectedDay} month={moment().add(2, 'month')}/>
			</div>
		</div>
	}
}

function map(state) {
	return {
		cycleLength: state.UserModule.cycleLength || 28,
		menstruatedAt: state.UserModule.menstruatedAt,
		menstruationLength: state.UserModule.menstruationLength,
		selectedDate: state.UserModule.selectedDate,
		popup: state.DailyStateModule.popup,
	}
}

export default connect(map, {openStateForSelectedDay})(CalendarScreen)
