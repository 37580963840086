import React from 'react'
import mount from "./tools/mount"
import VkSdk from "./Vk/VkSdk"
import store, {history} from './store'
import {Provider} from "react-redux"
import MobileContainer from "./containers/MobileContainer/MobileContainer"
import L from "./lang/L"
import VkConnect from '@vkontakte/vkui-connect'
import {ConnectedRouter} from "react-router-redux"
import {handleLocation, setUpdateFlag, subscribeToHistory} from "./modules/PageModule"
import Error from "./components/Error/Error"
import registerServiceWorker from "./registerServiceWorker"
import {updateUserName} from "./modules/UserModule"
import './style/index.css'


const onDownloadUpdate = () => {
	window.llog("UPDATED!")
	store.dispatch( setUpdateFlag(true) )
}

VkConnect.subscribe( event => {
	if (event && event.detail) {
		const {type, data} = event.detail
		if (type === "VKWebAppGetUserInfoResult") {
			const {first_name, last_name} = data
			window.llog(first_name + " " + last_name)
			store.dispatch( updateUserName( first_name, last_name ) )
		}
		if (type === "VKWebAppUpdateConfig") {
			window.llog("VKWebAppUpdateConfig")
			registerServiceWorker(onDownloadUpdate)
		}
 	}
} )
VkConnect.send("VKWebAppInit", {})
VkConnect.send("VKWebAppGetUserInfo", {})

/**
 * @type {VkStartParams}
 */
let startParams = VkSdk.getStartParams()
L.init(startParams.getLangCode()).then(() => {
	store.dispatch( updateUserName( "Евлампий", "" ) )
	store.dispatch(subscribeToHistory(history))
	store.dispatch(handleLocation(history.location.pathname))
	// store.dispatch(LoadFriends())
	mount(<Provider store={store}>
		<ConnectedRouter history={history}>
			<MobileContainer/>
		</ConnectedRouter>
	</Provider>)
}).catch(e => {
	mount(<Error error={e}/>)
})

window.llog = text => {
	// const now = new Date()
	// const time = now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds() + ":" + now.getMilliseconds() + 'ms'
	// let tn = document.createTextNode(time + ": " + text + "\n")
	// const consoleNode = document.querySelector('.CONSOLE')
	// if (consoleNode) {
	// 	consoleNode.prepend(tn)
	// } else {
	// 	setTimeout( () => window.llog(text), 1000 )
	// }
}

setTimeout(() => window.llog("Started"), 1000)

registerServiceWorker(onDownloadUpdate)
