import React, {Component} from "react"
import {connect} from "react-redux"
import "./MainScreen.css"
import {
	CapitalizeFirstLetter,
	DaysToMinstruation,
	DaysToOvulation,
	GetDayOfCycle,
	GetShotDateFormat,
	IsRiskDay
} from "../../tools/helpers"
import L from "../../lang/L"
// import DayList from "../DayList/DayList"
import DayActivity from "../DayActivity/DayActivity"
import {setUserSelectedDate} from "../../modules/UserModule"
import {changeDay} from "../../modules/DailyStateModule"

class MainScreen extends Component {

	state = {
		week: false
	}

	getSecondAdvice(dayOfCycle, menstruationLength, cycleLength, isRiskDay) {

		const daysToOvulation = DaysToOvulation(dayOfCycle, cycleLength)

		const daysToOvulationText = daysToOvulation === 0
			? L.t('ovulation_today')
			: L.t('x_day', {count: daysToOvulation}) + ' ' + L.t('to_ovulation')


		if (dayOfCycle < menstruationLength) {
			const timeToEnd = L.t("for_ending") + ' ' + L.t('x_day', {count:menstruationLength - (dayOfCycle+1)})

			return [timeToEnd, L.t('low_chance_for_pregnancy'), daysToOvulationText].join(', ')
		} else {
			const change = isRiskDay ? L.t('high_chance_for_pregnancy') : L.t('low_chance_for_pregnancy')
			const menstruationAfterText = L.t("m_after_x_day", {count: DaysToMinstruation(dayOfCycle, cycleLength, menstruationLength)})
			return [menstruationAfterText, change, daysToOvulationText].join(', ')
		}
	}

	getFirstAdvice(dayOfCycle, menstruationLength, cycleLength) {
		if (dayOfCycle < menstruationLength) {
			return L.t('menstruation') + ", " + L.t('x_day', {x:dayOfCycle+1})
		} else {
			return L.t('x_day_of_cycle', {count:dayOfCycle+1})

		}
	}

	onDayChange(dateMoment) {
		this.props.setUserSelectedDate(dateMoment)
		this.props.changeDay(dateMoment)
	}

	mainAdvice(dayOfCycle, menstruationLength, cycleLength, isRiskDay) {
		return <div className="MainScreen__advice">
			<div className="MainScreen__advice--header">
				{this.getFirstAdvice(dayOfCycle, menstruationLength, cycleLength)}
			</div>
			<div className="MainScreen__advice--body">
				{this.getSecondAdvice(dayOfCycle, menstruationLength, cycleLength, isRiskDay)}
			</div>
		</div>
	}

	getAdviceForOffset(offset) {
		const {cycleLength, menstruatedAt, menstruationLength, selectedDate} = this.props
		const day = selectedDate.clone().add(offset, 'days')
		const dayOfCycle = GetDayOfCycle(cycleLength, menstruatedAt.timestampMs(), day)
		const isRiskDay = IsRiskDay(cycleLength, menstruatedAt.timestampMs(), day)

		return <div key={offset} className="MainScreen__advice">
			<div className="MainScreen__advice--header">
				{CapitalizeFirstLetter(day.format('dd'))}, {GetShotDateFormat(day)}
			</div>
			<div className="MainScreen__advice--body">
				{this.getSecondAdvice(dayOfCycle, menstruationLength, cycleLength, isRiskDay)}
			</div>
		</div>
	}

	weekAdvice() {
		return [
			this.getAdviceForOffset(0),
			this.getAdviceForOffset(1),
			this.getAdviceForOffset(2),
			this.getAdviceForOffset(3),
			this.getAdviceForOffset(4),
			this.getAdviceForOffset(5),
			this.getAdviceForOffset(6),
			<div className="MainScreen__fake" key={"FAKE"}>&nbsp;</div>
		]
	}


	render() {
		const {cycleLength, menstruatedAt, menstruationLength, selectedDate, firstName} = this.props

		const dayOfCycle = GetDayOfCycle(cycleLength, menstruatedAt.timestampMs(), selectedDate)
		const isRiskDay = IsRiskDay(cycleLength, menstruatedAt.timestampMs(), selectedDate)
		const isBadDay = dayOfCycle <= menstruationLength

		let classList = ["MainScreen"]
		if (!isBadDay) {
			classList.push("MainScreen--green")
		}
		return <div style={ {minHeight: this.props.height + "px"} } className={classList.join(' ')}>
			<div className="MainScreen__text">
				{L.t("hello_username")}<br/>
				{firstName}
			</div>
			<div className="MainScreen__separator"/>
			<div className="MainScreen__tabs">
				<button onClick={() => this.setState({week:false})}
						className={this.state.week ? '' : 'active'}>{L.t('today')}</button>
				<button onClick={() => this.setState({week:true})}
						className={!this.state.week ? '' : 'active'}>{L.t('for_a_week')}</button>
			</div>
			<div className="MainScreen__advice-wrapper">
				{this.state.week ? this.weekAdvice() : this.mainAdvice(dayOfCycle, menstruationLength, cycleLength, isRiskDay)}
			</div>
			<DayActivity isBadDay={isBadDay}/>
		</div>
	}
}

function map(state) {
	return {
		cycleLength: state.UserModule.cycleLength || 28,
		menstruationLength: state.UserModule.menstruationLength,
		menstruatedAt: state.UserModule.menstruatedAt,
		selectedDate: state.UserModule.selectedDate,
		firstName: state.UserModule.firstName,
	}
}

export default connect(map, {setUserSelectedDate, changeDay})(MainScreen)
