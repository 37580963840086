import React, {Component} from "react"
import {connect} from "react-redux"
import "./Timing.css"
import L from "../../lang/L"
import {FixedLayout} from "@vkontakte/vkui"
import {getPathByPanelId, PANEL_INTRO, pushPage} from "../../modules/PageModule"

class Welcome extends Component {

	toIntro() {
		this.props.pushPage(getPathByPanelId(PANEL_INTRO))
	}

	render() {
		return <div className="Timing Timing--welcome">
			<div className="Timing__title Timing__title--black">
				{L.t('look_for_health')}
			</div>
			<FixedLayout vertical="bottom">
				<div className="Timing__bottom-single Timing__bottom-single--shadowed"
					 onClick={() => this.toIntro()}>
					<button className="Btn Timing__button-next">
						{L.t('next')}
					</button>
				</div>
			</FixedLayout>
		</div>
	}
}

function map(state) {
	return {

	}
}

export default connect(map, {pushPage})(Welcome)
