import React, {Component} from "react"
import {connect} from "react-redux"
import moment from "moment"
import Switch from "@vkontakte/vkui/dist/components/Switch/Switch"
import {platform, IOS} from "@vkontakte/vkui"
import L from "../../lang/L"
import {
	setUserBdate,
	setUserCycleLength,
	setUserMenstruationLength,
	updateUser, updateUserPushKey
} from "../../modules/UserModule"
import {toggleFriend, togglePopup} from "../../modules/FriendModule"


import "./UserProfile.css"

class UserProfile extends Component {

	state = {
	}

	toggleFriendsAddPanel() {
		this.props.togglePopup()
	}

	select(count, offset, value, onSelect) {
		let items = [];
		for (let i = offset; i < count; i++) {
			items.push(<option key={i} value={i}>{i}</option>)
		}
		return <select className="UserProfile__select" value={value}
					   onChange={e => onSelect(parseInt(e.target.value, 10))}>
			{items}
		</select>
	}

	setUserCycleLength(x) {
		this.props.setUserCycleLength(x)
		this.props.updateUser()
	}

	setUserMenstruationLength(x) {
		this.props.setUserMenstruationLength(x)
		this.props.updateUser()
	}

	setUserBdate(moment) {
		this.props.setUserBdate(moment)
		this.props.updateUser()
	}

	renderPopupButton() {
		return null
	}

	onBottomPopupClose() {
		this.toggleFriendsAddPanel()
	}

	render() {
		const {user} = this.props
		const osname = platform()

		return <div className="UserProfile">
			<div className={"UserProfile__header" + (osname !== IOS ? " android" : "")}>
				{L.t('profile')}
			</div>
			<div className="UserProfile__panel">
				<div className="UserProfile__basic">
					{L.t('basic_info')}
				</div>
				<div className="UserProfile__line">
					{this.select(31, 10, this.props.cycleLength, x => this.setUserCycleLength(x))}
					<div className={"left"}>
						{L.t('cycle_length_short')}
					</div>
					<div className={"right"}>
						{L.t('x_day', {count: this.props.cycleLength})}
					</div>
				</div>
				<div className="UserProfile__line">
					{this.select(10, 1, this.props.menstruationLength, x => this.setUserMenstruationLength(x))}
					<div className={"left"}>
						{L.t('menstruation_length_short')}
					</div>
					<div className={"right"}>
						{L.t('x_day', {count: this.props.menstruationLength})}
					</div>
				</div>
				<div className="UserProfile__line">
					<input type="date"
						   className={"UserProfile__select"}
						   value={this.props.bdate.format("YYYY-MM-DD")}
						   onChange={(e) => this.setUserBdate(moment(e.target.value))}/>
					<div className={"left"}>
						{L.t('bdate_short')}
					</div>
					<div className={"right"}>
						{this.props.bdate.format("DD.MM.YYYY")}
					</div>
				</div>
			</div>

			{/*<div className="UserProfile__panel">
				<div className="UserProfile__basic">
					{L.t('notifications')}
				</div>
				<div className="UserProfile__line">
					<div className={"left"}>
						{L.t('remember_about_contraception')}
					</div>
					<div className={"right"}>
						<Switch checked={user.pushContraception}
								onChange={() => this.props.updateUserPushKey("pushContraception", !user.pushContraception)}/>
					</div>
				</div>
				<div className="UserProfile__line">
					<div className={"left"}>
						{L.t('remember_about_ovulation')}
					</div>
					<div className={"right"}>
						<Switch checked={user.pushOvulation}
								onChange={() => this.props.updateUserPushKey("pushOvulation", !user.pushOvulation)}/>
					</div>
				</div>
				<div className="UserProfile__line">
					<div className={"left"}>
						{L.t('remember_about_risk')}
					</div>
					<div className={"right"}>
						<Switch checked={user.pushRisk}
								onChange={() => this.props.updateUserPushKey("pushRisk", !user.pushRisk)}/>
					</div>
				</div>
				<div className="UserProfile__line">
					<div className={"left"}>
						{L.t('remember_about_start')}
					</div>
					<div className={"right"}>
						<Switch checked={user.pushStart}
								onChange={() => this.props.updateUserPushKey("pushStart", !user.pushStart)}/>
					</div>
				</div>
			</div>*/}
		</div>
	}
}

function map(state) {
	return {
		cycleLength: state.UserModule.cycleLength || 28,
		menstruationLength: state.UserModule.menstruationLength,
		bdate: state.UserModule.bdate,
		friends: state.FriendModule.friends,
		selectedFriends: state.FriendModule.selected_friends,
		selectedIds: state.FriendModule.selected_ids,
		popupOpened: state.FriendModule.popupOpened,

		user: state.UserModule,
	}
}

export default connect(map, {
	setUserCycleLength,
	setUserBdate,
	setUserMenstruationLength,
	updateUser,
	togglePopup,
	toggleFriend,
	updateUserPushKey,
})(UserProfile)
