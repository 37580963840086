import React, {Component} from "react"
import {connect} from "react-redux"
import "./Timing.css"
import L from "../../lang/L"
import {FixedLayout} from "@vkontakte/vkui"
import {getPathByPanelId, PANEL_CYCLE_LENGTH, pushPage} from "../../modules/PageModule"

class Welcome extends Component {

	toCycleLength() {
		this.props.pushPage(getPathByPanelId(PANEL_CYCLE_LENGTH))
	}

	render() {
		return <div className="Timing Timing--intro">
			<div className="Timing__title Timing__title--black">
				{L.t('take_notifications')}
			</div>
			<FixedLayout vertical="bottom">
				<div className="Timing__bottom-single Timing__bottom-single--shadowed"
					 onClick={() => this.toCycleLength()}>
					<button className="Btn Timing__button-next">
						{L.t('next')}
					</button>
				</div>
			</FixedLayout>
		</div>
	}
}

function map(state) {
	return {

	}
}

export default connect(map, {pushPage})(Welcome)
