import React, {Component} from "react"
import {connect} from "react-redux"
import {closeCommentPopup} from "../../modules/DailyStateModule"
import "./CommentPopup.css"
import Comment from "../Comment/Comment"

class CommentPopup extends Component {

	onClose = () => {
		this.props.closeCommentPopup()
	}

	render() {
		return <div onClick={this.onClose} className="CommentPopup">
			<Comment/>
		</div>
	}
}

function map(state) {
	return {

	}
}

export default connect(map, {closeCommentPopup})(CommentPopup)
