import React, {Component} from "react"
import {connect} from "react-redux"
import DayActivity from "../DayActivity/DayActivity"
import {closeStatePopup, setEditDay} from "../../modules/DailyStateModule"
import "./DayActivityPopup.css"

class DayActivityPopup extends Component {

	onClose = () => {
		this.props.closeStatePopup()
	}

	render() {
		return <div onClick={this.onClose} className="DayActivityPopup">
			<DayActivity header={true}
						 short={this.props.short}
						 edit={true}
						 onClose={this.onClose}
						 onClick={e => e.stopPropagation()}/>
		</div>
	}
}

function map(state) {
	return {
		short: !state.DailyStateModule.edit
	}
}

export default connect(map, {closeStatePopup, setEditDay})(DayActivityPopup)
