import React, {Component} from "react"
import {connect} from "react-redux"
import "./Comment.css"
import WaitDots from "../WaitDots/WaitDots"
import {Textarea, Button} from "@vkontakte/vkui"
import L from "../../lang/L"
import {closeCommentPopup, setDailyStateComment, storeDailyState} from "../../modules/DailyStateModule"

class Comment extends Component {

	state = {
		comment: ''
	}

	constructor(props) {
		super(props)
		this.state.comment = props.comment
	}

	setComment(comment) {
		this.setState({comment})
	}

	render() {
		return <div className="Comment" onClick={e => e.stopPropagation()}>
			<div className="Comment__close" onClick={() => this.props.closeCommentPopup()}>
			</div>
			<div className="Comment__title">
				{L.t('comment')}
			</div>
			<div className="Comment__textarea">
				<Textarea top={L.t('comment')}
						  onChange={(e) => this.setComment(e.target.value)}
						  defaultValue={this.state.comment || ""}
						  value={this.state.comment || ""}
						  placeholder={L.t('comment_placeholder')}/>
			</div>
			<Button size="xl" onClick={() => this.props.storeDailyState(this.state.comment)}>
				{this.props.loading ? <WaitDots/> : L.t('save')}
			</Button>
		</div>
	}
}

function map(state) {
	return {
		loading: state.DailyStateModule.loading,
		comment: state.DailyStateModule.comment,
	}
}

export default connect(map, {setDailyStateComment, storeDailyState, closeCommentPopup})(Comment)
