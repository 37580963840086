import moment from "moment"

export default class DailyState {

	id
	date
	state
	comment
	loading = false
	popup = false
	edit = false
	commentPopup = false

	static fromRaw(raw) {
		const dailyState = new DailyState()
		dailyState.id = raw.id || 0
		dailyState.date = raw.date ? moment(raw.date*1000) : moment()
		dailyState.state = raw.state || []
		dailyState.comment = raw.comment || null
		dailyState.popup = raw.popup || false
		dailyState.edit = raw.edit || false
		return dailyState
	}

	toRaw() {
		return {
			date: this.date.unix(),
			state: this.state,
			comment: this.comment,
			popup: this.popup,
			edit: this.edit
		}
	}

	clone() {
		const dailyState = new DailyState()
		dailyState.id = this.id
		dailyState.date = this.date
		dailyState.state = this.state
		dailyState.loading = this.loading
		dailyState.comment = this.comment
		dailyState.popup = this.popup
		dailyState.edit = this.edit
		dailyState.commentPopup = this.commentPopup
		return dailyState
	}
}


