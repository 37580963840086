export default function ru() {
    return {
        translation: {
            oops: 'Что-то пошло не так',
			cycle_length: 'Укажите длину вашего цикла',
			cycle_length_short: "Длина цикла",
			menstruation_length: 'Укажите длину месячных',
			menstruation_length_short: "Длина месячных",
			menstruated_at: 'Укажите начало последнего менструального цикла',
			bdate: 'Укажите вашу дату рождения',
			bdate_short: "Дата рождения",
			day: 'День',
			month: 'Месяц',
			year: 'Год',
			next: 'Дальше',
			back: 'Назад',
			month_1:"Январь",
			month_2:"Февраль",
			month_3:"Март",
			month_4:"Апрель",
			month_5:"Май",
			month_6:"Июнь",
			month_7:"Июль",
			month_8:"Август",
			month_9:"Сентябрь",
			month_10:"Октябрь",
			month_11:"Ноябрь",
			month_12:"Декабрь",

			short_month_01: "янв",
			short_month_02: "фев",
			short_month_03: "мар",
			short_month_04: "апр",
			short_month_05: "май",
			short_month_06: "июн",
			short_month_07: "июл",
			short_month_08: "авг",
			short_month_09: "сен",
			short_month_10: "окт",
			short_month_11: "ноя",
			short_month_12: "дек",

			request_data: "Подписаться",
			show_data: "Перейти",
			status_0:"Разрешение запрошено",
			status_1:"Вы подписаны",
			status_2:"Запрещено",
			friend_list: "Ваши друзья",
			menstruation:"Месячные",
			current_cycle: "Текущий цикл",
			x_day: "{{x}} день",

			x_day_0: "{{count}} день",
			x_day_1: "{{count}} дня",
			x_day_2: "{{count}} дней",
			for_ending: "До окончания",
			low_chance_for_pregnancy: "низкая вероятность забеременеть",
			high_chance_for_pregnancy: "высокая вероятность забеременеть",
			ovulation_today: "Овуляция сегодня",
			to_ovulation: "до овуляции",

			x_day_of_red_0: "{{count}} день менструации",
			x_day_of_red_1: "{{count}} день менструации",
			x_day_of_red_2: "{{count}} день менструации",

			x_day_of_cycle_0: "{{count}} день цикла",
			x_day_of_cycle_1: "{{count}} день цикла",
			x_day_of_cycle_2: "{{count}} день цикла",

			week_day_short_0:"вс",
			week_day_short_1:"пн",
			week_day_short_2:"вт",
			week_day_short_3:"ср",
			week_day_short_4:"чт",
			week_day_short_5:"пт",
			week_day_short_6:"сб",

			low_chance_to_peer: "Низкая вероятность забеременеть",
			hight_chance_to_peer: "Высокая вероятность забеременеть",
			ovulation_after_x_day_0:"Овуляция через {{count}} день",
			ovulation_after_x_day_1:"Овуляция через {{count}} дня",
			ovulation_after_x_day_2:"Овуляция через {{count}} дней",

			m_after_x_day_0:"Месячные через {{count}} день",
			m_after_x_day_1:"Месячные через {{count}} дня",
			m_after_x_day_2:"Месячные через {{count}} дней",

			main_tab_activity: "Состояние",
			main_tab_friends: "Друзья",
			main_tab_advice: "Советы",

			category_mood: "Настроение",
			category_sign: "Симптомы",
			category_fill: "Выделения",
			category_events: "Другие события",

			my_days_0: "день",
			my_days_1: "дня",
			my_days_2: "дней",

			profile: "Профиль",
			basic_info: "Основная информация",
			no_access: "Вы пока не предоставили доступ друзьям",
			add_friend: "Добавить друга",
			users_add: 'Добавление пользователей',
			accessed_friends: 'Друзья, имеющие доступ',
			change: 'Изменить',
			no_friends: 'Список друзей пуст',
			comment: 'Заметка',
			comment_placeholder: 'Опишите ваше состояние, мысли, \n' +
			'события дня…',
			save: 'Сохранить',
			look_for_health: 'Следите за своим циклом и здоровьем',
			take_notifications: 'Получайте оповещения о скором начале месячных и дне овуляции',

			hello_username: "Добрый день,",
			notifications: "Уведомления",
			remember_about_contraception: "Напомнить о приеме противозачаточных",
			remember_about_ovulation: "Напомнить о приближении овуляции",
			remember_about_risk: "Напомнить риск беременности ежедневно",
			remember_about_start: "Напомнить о приближении начала месячных",
			no_data_for_this_day: "Не данных за этот день",
			ready: "Готово",

			today: "Сегодня",
			for_a_week: "На неделю",
        }
    }
}
