import React, {Component} from "react"
import {connect} from "react-redux"
import {classNames, GetDayOfCycle, GetShotDateFormat} from "../../tools/helpers"
import L from "../../lang/L"
import DayState from "../DayState/DayState"
import "./DayActivity.css"
import {setEditDay, storeDailyState} from "../../modules/DailyStateModule"
import {FixedLayout, Button} from "@vkontakte/vkui"

class DayActivity extends Component {

	onClick = event => {
		if (this.props.onClick) {
			this.props.onClick(event)
		}
	}

	renderContent() {
		return <DayState edit={this.props.edit} short={this.props.short} isBadDay={this.props.isBadDay}/>
	}

	header() {
		const {
			selectedDate,
			cycleLength,
			menstruatedAt,
		} = this.props

		const date = GetShotDateFormat(selectedDate)
		const dayOfCycle = L.t('x_day_of_cycle', {
			count: GetDayOfCycle(cycleLength, menstruatedAt.timestampMs(), selectedDate)+1
		})

		return <div className={"DayActivity__header" + (!this.props.short ? " DayActivity__header--edit" : "")}>
			<div className="DayActivity__close" onClick={this.props.onClose}/>
			{date}, {dayOfCycle}
		</div>
	}

	onReady() {
		this.props.storeDailyState()
		this.props.setEditDay(false)
	}

	editFooter() {
		if (!this.props.short) {
			return <FixedLayout vertical="bottom" className="DayActivity__ready-button-wrapper">
				<Button size="xl" onClick={() => this.onReady()} className="DayActivity__ready-button">{L.t('ready')}</Button>
			</FixedLayout>
		}
		return <button onClick={() =>this.props.setEditDay(true)} className="DayActivity__edit-button">{L.t('change')}</button>
	}

	render() {
		const {header, edit} = this.props
		return <div onClick={this.onClick} className={classNames({
			"DayActivity":true,
			"DayActivity--footer":edit
		})}>
			{header && this.header()}
			<div className="DayActivity__content">
				{this.renderContent()}
			</div>
			{edit ? this.editFooter() : null}
		</div>
	}
}

DayActivity.propTypes = {}

function map(state) {
	return {
		selectedDate: state.DailyStateModule.date,
		cycleLength: state.UserModule.cycleLength || 28,
		menstruatedAt: state.UserModule.menstruatedAt,
		menstruationLength: state.UserModule.menstruationLength,
	}
}

export default connect(map, {setEditDay, storeDailyState})(DayActivity)
