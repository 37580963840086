import React, {Component} from "react"
import {connect} from "react-redux"
import "./Calendar.css"
import {classNames, GetDayOfCycle, IsOvulDay, IsRiskDay} from "../../tools/helpers"
import L from "../../lang/L"
import moment from "moment"

class Calendar extends Component {

	mainNode = null

	onClick = day => {
		if (this.props.onClick) {
			this.props.onClick(day)
		}
	}

	isRedDay(day) {
		const {
			cycleLength,
			menstruatedAt,
			menstruationLength
		} = this.props
		const dayOfCycle = GetDayOfCycle(cycleLength, menstruatedAt.timestampMs(), day)
		return dayOfCycle < menstruationLength
	}

	isFirstRed(day) {
		const {
			cycleLength,
			menstruatedAt,
		} = this.props
		const dayOfCycle = GetDayOfCycle(cycleLength, menstruatedAt.timestampMs(), day)
		return dayOfCycle === 0
	}

	isOvulDay(day) {
		const {
			cycleLength,
			menstruatedAt,
		} = this.props
		return IsOvulDay(cycleLength, menstruatedAt.timestampMs(), day)
	}

	isRiskDay(day) {
		const {
			cycleLength,
			menstruatedAt,
		} = this.props
		return IsRiskDay(cycleLength, menstruatedAt.timestampMs(), day)
	}

	isLastRed(day) {
		const {
			cycleLength,
			menstruatedAt,
			menstruationLength
		} = this.props
		const dayOfCycle = GetDayOfCycle(cycleLength, menstruatedAt.timestampMs(), day)
		return dayOfCycle === menstruationLength-1
	}

	isSameMonth(day) {
		let {month} = this.props
		return month.month() === day.month()
	}

	getDayInMonth() {
		let {month} = this.props
		return month.clone().endOf('month').date()
	}

	getWeeksInMonth() {
		let {month} = this.props
		let firstDayOfMonthWeekDay = month.clone().startOf('month').days()
		return Math.ceil((this.getDayInMonth()+firstDayOfMonthWeekDay)/7)
	}

	getFirstDayOfFirstWeek() {
		let {month} = this.props
		let firstDayOfMonthWeekDay = month.clone().startOf('month').days()
		return month.clone().startOf('month').subtract(firstDayOfMonthWeekDay, 'days').add(1, 'day')
	}

	getDayOffset(offset) {
		return this.getFirstDayOfFirstWeek().add(offset, 'days')
	}

	renderDay(offset) {
		const day = this.getDayOffset(offset)
		const name = classNames({
			"Calendar__day":true,
			"Calendar__day--today":day.isSame(moment(), 'day'),
			"Calendar__day--red": this.isRedDay(day),
			"Calendar__day--red-first": this.isFirstRed(day),
			"Calendar__day--red-last": this.isLastRed(day),
			"Calendar__day--ovul": this.isOvulDay(day),
			"Calendar__day--risk": this.isRiskDay(day)
		})
		return <td onClick={() => this.onClick(day)}
				   className={name}
				   key={day}>{day.date()}</td>
	}

	renderWeek(weekNumber) {
		return <tr key={weekNumber}>
			{this.renderDay(weekNumber*7+0)}
			{this.renderDay(weekNumber*7+1)}
			{this.renderDay(weekNumber*7+2)}
			{this.renderDay(weekNumber*7+3)}
			{this.renderDay(weekNumber*7+4)}
			{this.renderDay(weekNumber*7+5)}
			{this.renderDay(weekNumber*7+6)}
		</tr>
	}



	render() {
		const weeks = []
		for (let i = 0; i < this.getWeeksInMonth(); i++) {
			weeks.push(this.renderWeek(i))
		}
		const {month} = this.props
		return <div ref={node => this.props.onRef ? this.props.onRef(node) : null} className="Calendar">
			<div className="Calendar__month-name">{L.t("month_"+(month.month()+1))}</div>
			<table cellSpacing={0} cellPadding={0} className="Calendar__table">
				<tbody>
				{weeks}
				</tbody>
			</table>
			<div className="Calendar__separator"/>
		</div>
	}
}


function map(state) {
	return {
		cycleLength: state.UserModule.cycleLength || 28,
		menstruatedAt: state.UserModule.menstruatedAt,
		menstruationLength: state.UserModule.menstruationLength,
	}
}

export default connect(map, {})(Calendar)
